<template>
  <div class="select-language">
    <div class="el-select-language">
      <!-- <img :src="localeLanguage.logoPath" class="local-img" /> -->
      <el-select v-model="localeLanguage.languageId" placeholder="Select" styple="width:100%" @change="handleChange">
        <template #prefix>
          <img :src="localeLanguage.logoPath" class="local-img" />
        </template>
        <el-option
          v-for="item in languageList"
          :key="item.languageId"
          :label="item.name"
          :value="item.languageId"
        >
          <div class="d-flex">
            <div class=""><img :src="item.logoPath" class="language-img" /></div>
            <div class="">{{item.name}} </div>
          </div>
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import {getLanguageList, changeLanguage,getLanguageConfig} from "@/libs/api/language.js"
import i18n from '@/libs/i18n/index'
export default {
  components: {
  },
  data() {
    return {
      languageList:[],
      localeLanguage:JSON.parse(localStorage.getItem('localLanguage'))
    }
  },
  created() {
    // this.localLanguage = JSON.parse(localStorage.getItem('localLanguage'))
    this.getData()
  },
  methods: {
    // 获取语言信息
    getData(){
      getLanguageList().then(res=>{
        if(res.status==200){
          if(res.data.state==true){
            this.languageList = res.data.data;
          }
        }
      })
    },
    // 切换语言信息
    handleChange(id){
      console.log('点击了',id)
      let arr = this.languageList.filter(item => item.languageId === id)
      let locale = arr[0]
      localStorage.setItem("li18",locale.languageCode);
      localStorage.setItem("language",this.switchLanguageCode(locale.languageCode));
      localStorage.setItem('localLanguage', JSON.stringify(locale))
      // this.$i18n.locale = this.switchLanguageCode(locale.languageCode)
      this.localeLanguage.logoPath = locale.logoPath
      this.changeLanguage(locale.languageId,locale.languageCode)
      // this.getLanguageConfig(this.switchLanguageCode(locale.languageCode))
    },
    switchLanguageCode(code){
      switch (code) {
        case 'en_US':
          code ="en"
          break
        case 'zh_CN':
          code ="zh"
          break
      }
      return code
    },
    // 切换语言---api
    async changeLanguage(id,code){
      try {
        const res = await changeLanguage({languageId:id})
        let resonse = res.data
        if(resonse.state){
          this.$store.dispatch("appConfig/setLanguage", this.$i18n.locale);
          // this.getLanguageConfig(code)
          // window.location.href = location.origin;
          window.history.go(0)
          // this.$i18n.locale = this.switchLanguageCode(code)
        } else {
          this.$message.error(resonse.message)
        }
        
      } catch (err) {
        const { message} = err;
        this.$message.error(message)
      }
    },
    //通过接口获取语言配置json
    async getLanguageConfig(code){
      var res = await getLanguageConfig({fileName:code+'.json'})
      let resonse = res.data
      console.log('获取配置文件getLanguageConfig',JSON.parse(resonse.data))
      let key = JSON.parse(resonse.data)
      i18n.setLocaleMessage(code,key)
      setTimeout(() => {
        window.location.href = location.origin;
      },300)
      
    },

  },
}
</script>

<style lang="scss" scoped>
  .select-language{
    .el-select-language{
      display: flex;
      align-items: center;
      width: 100px;
      margin-right: -10px;
      .local-img {
        width: 20px;
        height:20px;
        margin-top: 10px;  
      }
    }
    ::v-deep .el-input__suffix{
      display: none;
    }
    ::v-deep .el-input__inner{
      border:none;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
    }
  }
  .language-img{
    width: 20px;
    height:20px;
    margin-right: 6px;
  }
  .li18{
    height: 40px;
    display: flex;
    border-bottom: 1px solid #EEEEEE;
  }
  .li18 div{
    line-height: 40px;
  }
  .li18 div img{
    width: 30px;
  }
  .li18>span{
    display: block;
    padding-left: 4px;
    line-height: 40px;

  }
</style>
