import router from "../axios";
import config from "../../config/index"
const { baseUrl } = config;

function getLanguageList() {
  //获取语言列表
  return router({
    url: baseUrl + "language/v1/getList",
    method: "POST",
  });
}

function changeLanguage(data) {
  //切换语言
  return router({
    url: baseUrl + "user/v1/changeLanguage",
    data: data,
    method: "POST",
  });
}

function getLanguageConfig (data) {
  //切换语言
  return router({
    url: baseUrl + "file/v1/i18n/web",
    data: data,
    method: "POST",
  });
}

export {
  getLanguageList,
  changeLanguage,
  getLanguageConfig
}