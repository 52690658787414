<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.fullName || userData.username }}
        </p>
        <template v-if="userData.userRoleId === '1' || userData.userRoleId === '2' || userData.userRoleId === '3' || userData.userRoleId === '4' || userData.userRoleId === '5'">
          <span class="user-status">{{ $t(`header.role${userData.userRoleId}`) }}</span>
        </template>
        <span v-else class="user-status">{{ userData.userRoleName }}</span>
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.fullName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: 'personal-information'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>{{$t('header.Profile')}}</span>
    </b-dropdown-item>
    <!-- <b-dropdown-item
      link-class="d-flex align-items-center"
       @click="doument()"
    >
      <feather-icon
        size="16"
        icon="MessageSquareIcon"
        class="mr-50"
      />
      <span>{{$t('header.Feedback')}}</span>
    </b-dropdown-item> -->
    <b-dropdown-item
      :to="{ name: 'about-us' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MessageSquareIcon"
        class="mr-50"
      />
      <span>{{$t('header.About Us')}}</span>
    </b-dropdown-item>
    <!-- <b-dropdown-item
      :to="{ name: 'message' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="BellIcon"
        class="mr-50"
      />
      <span>message</span>
    </b-dropdown-item> -->

    <!-- <b-dropdown-divider /> -->
    <!-- <b-dropdown-item
      :to="{ name: 'system-management-store-level' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="BellIcon"
        class="mr-50"
      />
      <span>【二期】门店层级</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'unit-conversion' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="BellIcon"
        class="mr-50"
      />
      <span>【二期】单位转换</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'email-config' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="BellIcon"
        class="mr-50"
      />
      <span>【二期】邮箱配置</span>
    </b-dropdown-item> -->

    <b-dropdown-divider />
    <!-- <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'pages-pricing' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CreditCardIcon"
        class="mr-50"
      />
      <span>Pricing</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>FAQ</span>
    </b-dropdown-item> -->
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>{{$t('header.Logout')}}</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  methods: {
    doument(){
      document.querySelector(".feealer").style="display:block";
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
